import React, { useEffect, useState } from "react";
import { Button, Table } from "@themesberg/react-bootstrap";
import { fetchApi } from "../../redux/api";
import CustomTable from "../../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../routes";
import { accountTypes, bankList } from "./AccountForm";
import { Badge } from "react-bootstrap";
import { useParams } from "react-router-dom";

const ManageAccount = () => {
  const { type } = useParams();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    fetchApi("pan_details/get")
      .then((resp) => {
        setIsLoading(false);
        if (resp) {
          setList(resp.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <div>
      <h6>Manage Accounts</h6>
      <div>
        <Button onClick={() => navigate(Routes.account.accountForm)}>
          Add New Account
        </Button>
      </div>
      <CustomTable
        isLoading={isLoading}
        fields={[
          {
            title: "Account Name",
            value: "account_name",
          },
          {
            title: "Account Number",
            value: "account_number",
            className: "text-center",
          },
          {
            title: "Account Type",
            // value: "account_type",
            custom: true,
            component: (item) => {
              let selected = accountTypes.find(
                (i) => i.code === item.account_type
              );
              return (
                <div>{selected ? `${selected.title}(${item.type}) ` : ""}</div>
              );
            },
          },
          {
            title: "Bank Code",
            // value: "bank_code",
            custom: true,
            component: (item) => {
              let selected = bankList.find((i) => i.code === item.bank_code);
              return <div>{selected ? selected.title : ""}</div>;
            },
          },
          {
            title: "Status",
            // value: "bank_code",
            custom: true,
            component: (item) => (
              <div className="text-center">
                <Badge>{item.status}</Badge>
              </div>
            ),
          },
          {
            title: "Action",
            custom: true,
            component: (item) => (
              <div className="text-center">
                <Button
                  className="me-1"
                  size="sm"
                  onClick={() =>
                    navigate(`${Routes.account.viewAccount}/${item.id}`)
                  }
                >
                  View
                </Button>
              </div>
            ),
          },
        ]}
        data={list?.filter((item) => item.type === type)}
      />
    </div>
  );
};

export default ManageAccount;
