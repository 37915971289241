/* eslint-disable react/prop-types */
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import React from "react";
import Roboto_BOLD from "../../../assets/fonts/Roboto-Bold.ttf";
import Roboto_ITALIC from "../../../assets/fonts/Roboto-Italic.ttf";
import Roboto_NORMAL from "../../../assets/fonts/Roboto-Regular.ttf";
import { formatNumber } from "../../../redux/utils";
import { toWordsconver } from "../../../redux/api";

Font.register({
  family: "Roboto",
  fonts: [
    { src: Roboto_NORMAL, fontWeight: 700 },
    {
      src: Roboto_BOLD,
      fontStyle: "bold",
    },
    {
      src: Roboto_ITALIC,
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    width: "100%",
    pageBreakInside: "avoid",
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 10,
    flexDirection: "row",
    lineHeight: 1.5,
  },
  leftBody: {
    width: "75%",
    height: "76vh",
    paddingTop: 10,
    borderRight: "2px solid #000",
  },
  rightBody: {
    width: "30%",
    paddingLeft: 20,
    paddingTop: 10,
  },
  table: {
    borderTop: 1,
    borderBottom: 1,
    width: "100%",
    flexDirection: "row",
  },
  image: {
    width: 30,
    height: 30,
  },
  image1: {
    width: 110,
    height: 100,
    marginLeft: -15,
    marginTop: 50,
  },
});

export const PaymentVoucherPdf = ({
  form = {},
  user = {},
  data = [],
  taxesApplied = [],
}) => {
  const convertAmountToWords = (amount) => {
    const units = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
    ];
    const teens = [
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    if (amount === 0) {
      return "zero";
    }

    let words = "";

    if (amount >= 1000000000) {
      words +=
        convertAmountToWords(Math.floor(amount / 1000000000)) + " billion ";
      amount %= 1000000000;
    }

    if (amount >= 1000000) {
      words += convertAmountToWords(Math.floor(amount / 1000000)) + " million ";
      amount %= 1000000;
    }

    if (amount >= 1000) {
      words += convertAmountToWords(Math.floor(amount / 1000)) + " thousand ";
      amount %= 1000;
    }

    if (amount >= 100) {
      words += convertAmountToWords(Math.floor(amount / 100)) + " hundred ";
      amount %= 100;
    }

    if (amount > 0) {
      if (words !== "") {
        words += "and ";
      }

      if (amount < 10) {
        words += units[amount];
      } else if (amount < 20) {
        words += teens[amount - 10];
      } else {
        words += tens[Math.floor(amount / 10)];
        if (amount % 10 > 0) {
          words += " " + units[amount % 10];
        }
      }
    }

    return words.trim();
  };

  // Function to capitalize the first letter of each number word
  const capitalizeNumberWords = (string) => {
    if (!string) return "";
    const numberWords = [
      "zero",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
      "hundred",
      "thousand",
      "million",
      "billion",
    ];

    return string
      .split(" ")
      .map((word) => {
        return numberWords.includes(word.toLowerCase())
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : word;
      })
      .join(" ");
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  let totalAmount = data.reduce((a,b) => a + parseFloat(b.amount), 0);

  let totalTaxesAmount = taxesApplied.reduce((a,b) => a + parseFloat(b.amount), 0);

  let netAmount = parseFloat(totalAmount) - parseFloat(totalTaxesAmount)

  data.forEach((item) => {
    totalAmount += parseInt(item.amount || 0);
  });

  taxesApplied.forEach((tax) => {
    totalAmount -= parseInt(tax.amount || 0);
  });

  return (
    <Document>
      {data.map((item, index) => {
        const pendingSignature = item.history
          ? item.history.find((a) => a.pv_status === "Pending")
          : null;
        const reviewedSignature = item.history
          ? item.history.find((a) => a.pv_status === "Reviewed")
          : null;
        const checkedSignature = item.history
          ? item.history.find((a) => a.pv_status === "Checked")
          : null;
        const approvedSignature = item.history
          ? item.history.find((a) => a.pv_status === "Approved")
          : null;
        return (
          <Page size="A3" key={index}>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Image
                  src={require("../../../assets/img/KTSG.png")}
                  style={styles.image1}
                />
                <Text
                  style={{
                    fontSize: 17,
                    marginTop: 10,
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                  }}
                >
                  STATE GOVERNMENT
                </Text>
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                  }}
                >
                  MINISTRY OF FINANCE
                </Text>
                <Text
                  style={{
                    fontSize: 17,
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                  }}
                >
                  MINISTERIAL/DEPARTMENTAL PAYMENT VOUCHER
                </Text>
              </View>
              <View style={styles.body}>
                <View style={styles.leftBody}>
                  <Text
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "bold",
                      fontSize: 18,
                      width: "100%",
                      marginLeft: 317,
                    }}
                  >
                    PAYMENT VOUCHER
                    {/* {JSON.stringify({ reviewedSignature, pendingSignature })} */}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "bold",
                    }}
                  >
                    KTT.44
                  </Text>
                  <Text>Organisation: {item?.mda_name ? item.mda_name : '......................................................................................................'}</Text>
                <Text>Sub Organisation Name: {item?.sub ? item.sub : '......................................................................................................'}</Text>
                <Text>Sub Sub Organisation Name: {item?.sub_sub ? item.sub_sub : '......................................................................................................'}</Text>
                <Text>Sub Sub Sub Organisation Name: {item?.sub_sub_sub ? item.sub_sub_sub : '......................................................................................................'}</Text>
                  <Text
                    style={{
                      marginBottom: 2,
                    }}
                  >
                    Payment to:
                    {item?.payment_to ? item.payment_to : "................................................................................................................."}
                  </Text>
                  <View style={styles.table}>
                    <Text
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "bold",
                        width: "10%",
                        borderRight: 1,
                        textAlign: "right",
                      }}
                    >
                      Date
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "bold",
                        width: "50%",
                        borderRight: 1,
                        textAlign: "center",
                      }}
                    >
                      Detailed description of service
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "bold",
                        width: "10%",
                        borderRight: 1,
                        textAlign: "center",
                      }}
                    >
                      Rate
                    </Text>
                    <View
                      style={{
                        width: "30%",
                        flexDirection: "column",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Roboto",
                          fontStyle: "bold",
                          width: "100%",
                          borderRight: 1,
                          textAlign: "center",
                        }}
                      >
                        Amount
                      </Text>
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          borderTop: 1,
                        }}
                      >
                        <Text
                          style={{
                            width: "80%",
                            borderRight: 1,
                            paddingLeft: 10,
                          }}
                        >
                          N
                        </Text>
                        <Text
                          style={{
                            width: "20%",
                            borderRight: 1,
                            paddingLeft: 4,
                          }}
                        >
                          K
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={
                      taxesApplied
                        ? {
                            borderBottom: 1,
                            width: "100%",
                            flexDirection: "row",
                          }
                        : {
                            height: 320,
                          }
                    }
                  >
                    <View
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "bold",
                        width: "10%",
                        borderRight: 1,
                        textAlign: "right",
                      }}
                    >
                      {/* {item.description_code} */}
                    </View>
                    <View
                      style={{
                        fontFamily: "Roboto",
                        // fontStyle: "bold",
                        width: "50%",
                        height: 150,
                        borderRight: 1,
                        textAlign: "left",
                        paddingLeft: 5,
                      }}
                    >
                      <Text>{capitalizeFirstLetter(item?.narration)}</Text>
                    </View>
                    <View
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "bold",
                        width: "10%",
                        borderRight: 1,
                        textAlign: "center",
                      }}
                    ></View>
                    <View
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "bold",
                        width: "30%",
                        borderRight: 1,
                        textAlign: "center",
                        flexDirection: "row",
                      }}
                    >
                      <View
                        style={{
                          width: "83%",
                          borderRight: 1,
                          paddingLeft: 10,
                          textAlign: "right",
                        }}
                      >
                        <Text>{formatNumber(item?.amount)?.split(".")[0]}</Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          paddingLeft: 10,
                        }}
                      >
                        <Text>
                          {item?.amount?.toString()?.split(".")[1] || "00"}
                        </Text>
                      </View>
                    </View>
                  </View>
                  {taxesApplied.map((tax, idx) => (
                    <View
                      style={
                        taxesApplied
                          ? {
                              borderBottom: 1,
                              width: "100%",
                              flexDirection: "row",
                            }
                          : {
                              height: 320,
                            }
                      }
                    >
                      <View
                        key={idx}
                        style={{
                          fontFamily: "Roboto",
                          fontStyle: "bold",
                          width: "10%",
                          borderRight: 1,
                          textAlign: "right",
                        }}
                      >
                        {/* {item.description_code} */}
                      </View>
                      <View
                        style={{
                          fontFamily: "Roboto",
                          fontStyle: "bold",
                          width: "50%",
                          borderRight: 1,
                          textAlign: "left",
                          paddingLeft: 5,
                        }}
                      >
                        <Text>{tax?.description}</Text>
                      </View>
                      <View
                        style={{
                          fontFamily: "Roboto",
                          fontStyle: "bold",
                          width: "10%",
                          borderRight: 1,
                          textAlign: "center",
                        }}
                      ></View>
                      <View
                        style={{
                          fontFamily: "Roboto",
                          fontStyle: "bold",
                          width: "30%",
                          borderRight: 1,
                          textAlign: "center",
                          flexDirection: "row",
                        }}
                      >
                        <View
                          style={{
                            width: "83%",
                            borderRight: 1,
                            paddingLeft: 10,
                            textAlign: "right",
                          }}
                        >
                          <Text>
                            {formatNumber(tax?.amount)?.split(".")[0]}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "20%",
                            paddingLeft: 10,
                          }}
                        >
                          <Text>
                            {tax?.amount?.toString()?.split(".")[1] || "00"}
                          </Text>
                        </View>
                      </View>
                    </View>
                  ))}
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      borderBottom: 1,
                    }}
                  >
                    <Text
                      style={{
                        width: "60%",
                        borderRight: 1,
                        textAlign: "justify",
                        height: 50,
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      TOTAL AMOUNT IN WORDS:{" "}
                      {toWordsconver(netAmount.toString().split('.')[0])?.toUpperCase()}
                                NAIRA
                                {netAmount.toString().split('.')[1]&&netAmount.toString().split('.')[1] !== '00'
                                    ? ` AND ${toWordsconver(netAmount.toString().split('.')[1])?.toUpperCase()} KOBO`
                                    : null}{' '}
                                ONLY
                      {/* {capitalizeNumberWords(
                        convertAmountToWords(netAmount || 0)
                      )} */}
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "40%",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Roboto",
                          fontStyle: "bold",
                          marginLeft: 45,
                          marginTop: 5,
                        }}
                      >
                        TOTAL: {formatNumber(netAmount)?.split(".")[0]}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "Roboto",
                          fontStyle: "bold",
                          // marginLeft: 90,
                          marginTop: 5,
                        }}
                      >
                        {netAmount?.toString()?.split(".")[1] || "00"}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      borderBottom: 1,
                      paddingLeft: 2,
                      flexDirection: "column",
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      We certify that the details above are in accordance with
                      the relevant contract,{" "}
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      regulations or other authority under which the
                      Services/Goods were provided/purchased
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          width: "70%",
                        }}
                      >
                        Officer who prepared voucher, Signature:
                      </Text>
                      {pendingSignature?.signature ? (
                        <Image
                          style={styles.image}
                          src={pendingSignature.signature}
                        />
                      ) : null}
                    </View>
                    <Text
                      style={{
                        marginLeft: 53,
                        textTransform: "uppercase",
                      }}
                    >
                      Name (in block letters):
                      {pendingSignature?.fullname}
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          width: "50%",
                        }}
                      >
                        Officer who checked voucher, Signature:
                      </Text>
                      {checkedSignature?.signature ? (
                        <Image
                          style={styles.image}
                          src={checkedSignature.signature}
                        />
                      ) : null}
                    </View>
                    <Text
                      style={{
                        marginLeft: 53,
                        textTransform: "uppercase",
                      }}
                    >
                      Name (in block letters):{checkedSignature?.fullname}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      borderBottom: 1,
                      paddingLeft: 2,
                      flexDirection: "column",
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      We certify that the Service/Goods have been duly
                      performed/received, that financial
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      authority GW/.............. is held to incur this
                      expenditure and that the relevant D V.E
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      account entries have been made. We hereby authorized
                      payment.
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "column",
                      }}
                    >
                      <Text
                        style={{
                          width: "30%",
                        }}
                      >
                        Signature:
                      </Text>
                      {approvedSignature?.signature ? (
                        <Image
                          style={styles.image}
                          src={approvedSignature.signature}
                        />
                      ) : null}
                    </View>
                    <Text
                      style={{
                        width: "100%",
                        // marginLeft: "55%",
                      }}
                    >
                      (Accounting Officer)
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        marginBottom: 20,
                      }}
                    >
                      <Text
                        style={{
                          width: "50%",
                          textTransform: "uppercase",
                        }}
                      >
                        Name (in block letters): {approvedSignature?.fullname}
                      </Text>
                      <Text
                        style={{
                          width: "50%",
                        }}
                      >
                        Date: {item?.pv_date}
                      </Text>
                    </View>

                    <View
                      style={{
                        width: "100%",
                        flexDirection: "column",
                      }}
                    >
                      <Text
                        style={{
                          width: "30%",
                        }}
                      >
                        Signature:
                      </Text>
                      {reviewedSignature?.signature ? (
                        <Image
                          style={styles.image}
                          src={reviewedSignature.signature}
                        />
                      ) : null}
                    </View>
                    <Text
                      style={{
                        width: "100%",
                        // marginLeft: "55%",
                      }}
                    >
                      (Accounting Officer)
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        marginBottom: 20,
                      }}
                    >
                      <Text
                        style={{
                          width: "50%",
                          textTransform: "uppercase",
                        }}
                      >
                        Name (in block letters): {reviewedSignature?.fullname}
                      </Text>
                      <Text
                        style={{
                          width: "50%",
                        }}
                      >
                        Date: {item?.pv_date}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      borderBottom: 1,
                      paddingLeft: 2,
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        width: "50%",
                      }}
                    >
                      Cheque No:
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                      }}
                    >
                      Account No: {item?.contractor_acc_no}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      borderBottom: 1,
                      paddingLeft: 2,
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        width: "50%",
                      }}
                    >
                      Account Name: {item?.contractor_acc_name}
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                      }}
                    >
                      {" "}
                      Name of Bank: {item?.contractor_bank_name}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      paddingLeft: 2,
                      flexDirection: "column",
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      Received the sum of .....................................
                      Naira and......................................Kobo in
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      acquisition of the above Account
                      this.............................Day
                      of..........................20..........
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      Amount in word.....................................Name of
                      Payee...................................................
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      Witness in official
                      Name..........................................Rank.................................................
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "60%",
                        marginLeft: 40,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        <Text>Witness</Text>
                        <Text>Official</Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          marginLeft: 40,
                        }}
                      >
                        <Text>Name.......................................</Text>
                        <Text>
                          Rank........................................
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.rightBody}>
                  <View
                    style={{
                      width: "100%",
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Voucher Number
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        border: 1,
                        flexDirection: "row",
                      }}
                    >
                      {String(item?.pv_code)
                        .split("")
                        .map((digit, index) => (
                          <Text
                            key={index}
                            style={{
                              width: "33.3%",
                              textAlign: "center",
                              borderRight:
                                index < String(item?.pv_code).length - 1
                                  ? 1
                                  : 0,
                              paddingTop: 3,
                            }}
                          >
                            {digit}
                          </Text>
                        ))}
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      marginTop: 30,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Date/Month/Year
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        border: 1,
                        flexDirection: "row",
                      }}
                    >
                      {String(item?.date)
                        .split("")
                        .map((digit, index) => (
                          <Text
                            key={index}
                            style={{
                              width: "12.5%",
                              textAlign: "center",
                              borderRight:
                                index < String(item?.date).length - 1 ? 1 : 0,
                              paddingTop: 3,
                            }}
                          >
                            {digit}
                          </Text>
                        ))}
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      marginTop: 30,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Administrative Code
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        border: 1,
                        flexDirection: "row",
                      }}
                    >
                      {String(item?.mda_code)
                        .split("")
                        .map((digit, index) => (
                          <Text
                            key={index}
                            style={{
                              width: "8.3%",
                              textAlign: "center",
                              borderRight:
                                index < String(item?.mda_code).length - 1
                                  ? 1
                                  : 0,
                              paddingTop: 3,
                            }}
                          >
                            {digit}
                          </Text>
                        ))}
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      marginTop: 30,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Economic Code
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        border: 1,
                        flexDirection: "row",
                        height: 21,
                      }}
                    >
                      {String(item?.economic_code)
                        .split("")
                        .map((digit, index) => (
                          <Text
                            key={index}
                            style={{
                              width: "12.5%",
                              textAlign: "center",
                              borderRight:
                                index < String(item?.economic_code).length - 1
                                  ? 1
                                  : 0,
                              paddingTop: 3,
                            }}
                          >
                            {digit}
                          </Text>
                        ))}
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      marginTop: 30,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Program Code
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        border: 1,
                        flexDirection: "row",
                        height: 21,
                      }}
                    >
                      {item?.program_code && String(item?.program_code)
                        .split("")
                        .map((digit, index) => (
                          <Text
                            key={index}
                            style={{
                              width: "12.5%",
                              textAlign: "center",
                              borderRight:
                                index < String(item?.program_code).length - 1
                                  ? 1
                                  : 0,
                              paddingTop: 3,
                            }}
                          >
                            {digit}
                          </Text>
                        ))}
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      marginTop: 30,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Functional Code
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        border: 1,
                        flexDirection: "row",
                      }}
                    >
                      {/* {String(item?.funtional_code)
                    .split("")
                    .map((digit, index) => ( */}
                      <Text
                        key={index}
                        style={{
                          width: "100%",
                          textAlign: "center",
                          borderRight:
                            // index < String(item?.funtional_code).length - 1
                            1,
                          // : 0,
                          paddingTop: 20,
                        }}
                      >
                        {/* {digit} */}
                      </Text>
                      {/* ))} */}
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      marginTop: 50,
                    }}
                  >
                    <Text>Date received in Treasury </Text>
                    <Text>............................................</Text>
                    <Text
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      Check and passed at{" "}
                    </Text>
                    <Text>............................................</Text>
                    <Text
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      For{" "}
                    </Text>
                    <Text
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      Payable only at{" "}
                    </Text>
                    <Text>Checked by internal Auditor</Text>
                    <Text>(Name and Signature)</Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        );
      })}

      {taxesApplied.map((item, index) => {
        const info = data && data.length ? data[0] : {};
        return (
          <Page size="A3" key={index}>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Image
                  src={require("../../../assets/img/KTSG.png")}
                  style={styles.image1}
                />
                <Text
                  style={{
                    fontSize: 17,
                    marginTop: 10,
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                  }}
                >
                  STATE GOVERNMENT
                </Text>
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                  }}
                >
                  MINISTRY OF FINANCE
                </Text>
                <Text
                  style={{
                    fontSize: 17,
                    fontFamily: "DM_SANS",
                    fontStyle: "bold",
                  }}
                >
                  MINISTERIAL/DEPARTMENTAL PAYMENT VOUCHER
                </Text>
              </View>
              <View style={styles.body}>
                <View style={styles.leftBody}>
                  <Text
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "bold",
                      fontSize: 18,
                      width: "100%",
                      marginLeft: 317,
                    }}
                  >
                    PAYMENT VOUCHER
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "bold",
                    }}
                  >
                    KTT.44
                  </Text>
                  <Text>Organisation: {data[0]?.mda_name}</Text>
                  {/* <Text>Organisation: {item.mda_name}</Text> */}
                  <Text>Sub Organisation Name: {info?.sub}</Text>
                  <Text>Sub Sub Organisation Name: {info?.sub_sub}</Text>
                  <Text>Sub Sub Sub Organisation Name: {info?.sub_sub_sub}</Text>
                  <Text
                    style={{
                      marginBottom: 2,
                    }}
                  >
                    Payment to:
                    .................................................................................................................
                  </Text>
                  <View style={styles.table}>
                    <Text
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "bold",
                        width: "10%",
                        borderRight: 1,
                        textAlign: "right",
                      }}
                    >
                      Date
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "bold",
                        width: "50%",
                        borderRight: 1,
                        textAlign: "center",
                      }}
                    >
                      Detailed description of service
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "bold",
                        width: "10%",
                        borderRight: 1,
                        textAlign: "center",
                      }}
                    >
                      Rate
                    </Text>
                    <View
                      style={{
                        width: "30%",
                        flexDirection: "column",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Roboto",
                          fontStyle: "bold",
                          width: "100%",
                          borderRight: 1,
                          textAlign: "center",
                        }}
                      >
                        Amount
                      </Text>
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          borderTop: 1,
                        }}
                      >
                        <Text
                          style={{
                            width: "80%",
                            borderRight: 1,
                            paddingLeft: 10,
                          }}
                        >
                          N
                        </Text>
                        <Text
                          style={{
                            width: "20%",
                            borderRight: 1,
                            paddingLeft: 4,
                          }}
                        >
                          K
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={
                      taxesApplied.length
                        ? {
                            borderBottom: 1,
                            width: "100%",
                            flexDirection: "row",
                          }
                        : {
                            height: 320,
                          }
                    }
                  >
                    <View
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "bold",
                        width: "10%",
                        borderRight: 1,
                        textAlign: "right",
                        height: 180,
                      }}
                    >
                      {/* {item.description_code} */}
                    </View>
                    <View
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "bold",
                        width: "50%",
                        borderRight: 1,
                        textAlign: "left",
                        paddingLeft: 5,
                      }}
                    >
                      <Text>{item?.description}</Text>
                    </View>
                    <View
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "bold",
                        width: "10%",
                        borderRight: 1,
                        textAlign: "center",
                      }}
                    ></View>
                    <View
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "bold",
                        width: "30%",
                        borderRight: 1,
                        textAlign: "center",
                        flexDirection: "row",
                      }}
                    >
                      <View
                        style={{
                          width: "83%",
                          borderRight: 1,
                          paddingLeft: 10,
                          textAlign: "right",
                        }}
                      >
                        <Text>
                          {formatNumber(item?.amount)?.split(".")[0]}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "20%",
                          paddingLeft: 10,
                        }}
                      >
                        <Text>
                          {item?.amount?.toString()?.split(".")[1] || "00"}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      borderBottom: 1,
                    }}
                  >
                    <Text
                      style={{
                        width: "60%",
                        borderRight: 1,
                        textAlign: "justify",
                        paddingTop: 5,
                        paddingRight: 5,
                        height: 50,
                        paddingLeft: 5,
                      }}
                    >
                      Total Amount in words:{" "}
                      {toWordsconver(item.amount.toString().split('.')[0])?.toUpperCase()}
                                NAIRA
                                {item.amount.toString().split('.')[1]&&item.amount.toString().split('.')[1] !== '00'
                                    ? ` AND ${toWordsconver(item.amount.toString().split('.')[1])?.toUpperCase()} KOBO`
                                    : null}{' '}
                                ONLY
                      {/* {capitalizeNumberWords(
                        convertAmountToWords(item?.amount || 0)
                      )} */}
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "40%",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Roboto",
                          fontStyle: "bold",
                          marginLeft: 45,
                          marginTop: 5,
                        }}
                      >
                        TOTAL: {formatNumber(item?.amount)?.split(".")[0]}{" "}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "Roboto",
                          fontStyle: "bold",
                          // marginLeft: 90,
                          marginTop: 5,
                        }}
                      >
                        {form?.amount?.toString()?.split(".")[1] || "00"}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      borderBottom: 1,
                      paddingLeft: 2,
                      flexDirection: "column",
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      We certify that the details above are in accordance with
                      the relevant contract,{" "}
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      regulations or other authority under which the
                      Services/Goods were provided/purchased
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      Officer who prepared voucher, Signature:
                      ..................................................................
                    </Text>
                    <Text
                      style={{
                        marginLeft: 53,
                      }}
                    >
                      Name (in block letters):
                      {user?.fullname?.toUpperCase()}
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      Officer who checked voucher, Signature
                      ...................................................................
                    </Text>
                    <Text
                      style={{
                        marginLeft: 53,
                      }}
                    >
                      Name (in block letters):
                      ...........................................................................
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      borderBottom: 1,
                      paddingLeft: 2,
                      flexDirection: "column",
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      We certify that the Service/Goods have been duly
                      performed/received, that financial
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      authority GM/.............. is held to incur this
                      expenditure and that the relevant D V.E
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      account entries have been made. We hereby authorized
                      payment.
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      Signature:
                      ................................................... For{" "}
                      {user?.fullname}
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        marginLeft: "55%",
                      }}
                    >
                      (Accounting Officer)
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      Name (in block letters):
                      .................................................Date......................20................
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      Signature:
                      ................................................... For{" "}
                      {user?.fullname}
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                        marginLeft: "55%",
                      }}
                    >
                      (Accounting Officer)
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          width: "50%",
                        }}
                      >
                        Name (in block letters): {form?.contractor_name}
                      </Text>
                      <Text
                        style={{
                          width: "50%",
                        }}
                      >
                        Date: {form?.date}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      borderBottom: 1,
                      paddingLeft: 2,
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        width: "50%",
                      }}
                    >
                      Cheque No:
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                      }}
                    >
                      Account No: {form?.account_number}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      borderBottom: 1,
                      paddingLeft: 2,
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        width: "50%",
                      }}
                    >
                      Account Name: {form?.account_name}
                    </Text>
                    <Text
                      style={{
                        width: "50%",
                      }}
                    >
                      {" "}
                      Name of Bank: {form?.bank_name}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      paddingLeft: 2,
                      flexDirection: "column",
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      Received the sum of .....................................
                      Naira and......................................Kobo in
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      acquisition of the above Account
                      this.............................Day
                      of..........................20..........
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      Amount in word.....................................Name of
                      Payee...................................................
                    </Text>
                    <Text
                      style={{
                        width: "100%",
                      }}
                    >
                      Witness in official
                      Name..........................................Rank.................................................
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "60%",
                        marginLeft: 40,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        <Text>Witness</Text>
                        <Text>Official</Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "column",
                          marginLeft: 40,
                        }}
                      >
                        <Text>Name.......................................</Text>
                        <Text>
                          Rank........................................
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.rightBody}>
                  <View
                    style={{
                      width: "100%",
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Voucher Number
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        border: 1,
                        flexDirection: "row",
                      }}
                    >
                      {String(data[0]?.pv_code)
                        .split("")
                        .map((digit, index) => (
                          <Text
                            key={index}
                            style={{
                              width: "33.3%",
                              textAlign: "center",
                              borderRight:
                                index < String(data[0]?.pv_code).length - 1
                                  ? 1
                                  : 0,
                              paddingTop: 3,
                            }}
                          >
                            {digit}
                          </Text>
                        ))}
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      marginTop: 30,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Date/Month/Year
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        border: 1,
                        flexDirection: "row",
                      }}
                    >
                      {String(data[0]?.date)
                        .split("")
                        .map((digit, index) => (
                          <Text
                            key={index}
                            style={{
                              width: "12.5%",
                              textAlign: "center",
                              borderRight:
                                index < String(data[0]?.date).length - 1
                                  ? 1
                                  : 0,
                              paddingTop: 3,
                            }}
                          >
                            {digit}
                          </Text>
                        ))}
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      marginTop: 30,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Administrative Code
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        border: 1,
                        flexDirection: "row",
                      }}
                    >
                      {String(data[0]?.mda_code)
                        .split("")
                        .map((digit, index) => (
                          <Text
                            key={index}
                            style={{
                              width: "8.3%",
                              textAlign: "center",
                              borderRight:
                                index < String(data[0]?.mda_code).length - 1
                                  ? 1
                                  : 0,
                              paddingTop: 3,
                            }}
                          >
                            {digit}
                          </Text>
                        ))}
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      marginTop: 30,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Economic Code
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        border: 1,
                        flexDirection: "row",
                        height: 21,
                      }}
                    >
                      {String(data[0]?.economic_code)
                        .split("")
                        .map((digit, index) => (
                          <Text
                            key={index}
                            style={{
                              width: "12.5%",
                              textAlign: "center",
                              borderRight:
                                index <
                                String(data[0]?.economic_code).length - 1
                                  ? 1
                                  : 0,
                              paddingTop: 3,
                            }}
                          >
                            {digit}
                          </Text>
                        ))}
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      marginTop: 30,
                    }}
                  >
                    <Text
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Program Code
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        border: 1,
                        flexDirection: "row",
                        height: 21,
                      }}
                    >
                      {String(info?.program_code)
                        .split("")
                        .map((digit, index) => (
                          <Text
                            key={index}
                            style={{
                              width: "12.5%",
                              textAlign: "center",
                              borderRight:
                                index < String(item?.program_code).length - 1
                                  ? 1
                                  : 0,
                              paddingTop: 3,
                            }}
                          >
                            {digit}
                          </Text>
                        ))}
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      marginTop: 50,
                    }}
                  >
                    <Text>Date received in Treasury </Text>
                    <Text>............................................</Text>
                    <Text
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      Check and passed at{" "}
                    </Text>
                    <Text>............................................</Text>
                    <Text
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      For{" "}
                    </Text>
                    <Text
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      Payable only at{" "}
                    </Text>
                    <Text>Checked by internal Auditor</Text>
                    <Text>(Name and Signature)</Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        );
      })}
    </Document>
  );
};
