import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faSignOutAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
// import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import {
  Row,
  Col,
  Nav,
  Image,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
  // InputGroup,
} from "@themesberg/react-bootstrap";

// import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/default_avatar.png";
// import { SearchContext } from "../pages/HomePage";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Routes } from "../routes";
import { logout } from "../redux/actions/auth";
// import { Link } from "react-router-dom";
import './navbar.css'
import toast from "react-hot-toast";
import WebSocketService from "./WebSocketService";
import moment from "moment";
import NotificationSound from '../assets/notification.mp3';


export default (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  // const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const [notifications, setNotifications] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
  const areNotificationsRead = notifications?.reduce(
    (acc, notif) => acc && notif.read,
    true
  );

const userId = user.username;
const mda_name = user.mda_name;

useEffect(() => {
  if (userId !== undefined) {
      WebSocketService.connect(userId, mda_name);
  }      

 const playSound = () => {
   const audio = new Audio(NotificationSound);
   audio.play().catch((error) => {
     // Handle any playback errors
     console.error("Audio playback error:", error);
   });
 };
  WebSocketService.subscribeToNotifications((notification) => {
    console.log("Received notification:", notification);
    const data = notification[0]
    toast.custom(
      (t) => (
        <div
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } cus-width w-full bg-white shadow-lg justify-content-between rounded pointer-events-auto d-flex shadow ring-black ring-opacity-5`}
        >
          <div className="d-flex flex-1 w-0 p-2 py-2 justify-content-betweeen">
            <div className="d-flex items-start">
              <div
                className="flex-shrink-0 rounded-circle align-self-center"
                style={{ width: 45, height: 45 }}
              >
                <img
                  className="h-10 w-10 rounded-circle"
                  src={Profile3}
                  alt="Profile"
                />
              </div>
              <div className="ms-2 flex-1">
                <p className="text-sm mb-1 text-primary">
                  {data.notification_type}
                </p>
                <p className="mt-1 text-sm mb-1">{`${data.message}`}</p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-item-center mx-2">
            <button
              onClick={() => {
                toast.dismiss(t.id);
                history(Routes.Notifications.path);
              }}
              className="w-full border rounded p-1 justify-center text-sm font-medium bg-primary text-white"
            >
              Open
            </button>
            <button
              onClick={() => {
                toast.dismiss(t.id);
              }}
              className="w-full border rounded p-1 justify-center text-sm font-medium bg-success text-white"
            >
              Dismiss
            </button>
          </div>
        </div>
      ),
      {
        duration: 30000,
      }
    );
 playSound();
    // setNotifications((prevNotifications) => [
    //   ...prevNotifications,
    //   notification,
    // ]);
  });

  WebSocketService.subscribeToAllNotifications((allNotifications) => {
    setNotifications(allNotifications);
    
  });

 
  // setInterval(() => {
  //    WebSocketService.sendNotification({
  //      message:
  //        "The following Release with Approval No. 123456 needs your attention",
  //      user_id: 100,
  //    });
  //   console.log('called')
  // }, 10000);

  return () => {
    WebSocketService.disconnect();
  };
}, [history, mda_name, userId]);

const handleToggle = (isOpen) => {
  setShowDropdown(isOpen);
};

const handleViewAllClick = () => {
  history(Routes.Notifications.path);
  setShowDropdown(false);
};

  const handleLogout = () =>
    dispatch(logout(() => history(Routes.Signin.path)));

  // const markNotificationsAsRead = () => {
  //   setTimeout(() => {
  //     setNotifications(notifications.map((n) => ({ ...n, read: true })));
  //   }, 300);
  // };
const markAsRead = (id) => {
   WebSocketService.markAsRead((id) => {
    console.log('excecuted')
     setNotifications((prevNotifications) =>
       prevNotifications.map((notification) =>
         notification.id === id
           ? { ...notification, status: "read" }
           : notification
       )
     );
   });

};
  const Notification = (props) => {
    const { id ,link, notification_type, message, status , created_at} = props;
    const readClassName = status ? "text-primary" : "text-danger";

    return (
      <ListGroup.Item
        action
        href={link}
        className="border-bottom border-light"
        onClick={() => markAsRead(id)}
      >
        <Row className="align-items-center">
          {/* <Col className="col-auto">
            <Image
              src={image}
              className="user-avatar lg-avatar rounded-circle"
            />
          </Col> */}
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{notification_type}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>
                  {moment(created_at).calendar()}
                </small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <>
      <Navbar
        // variant="dark"
        expanded
        className="ps-0 pe-2 pb-0 bg-primary"
        style={{
          borderRadius: "15px",
          width: "99%",
          margin: "5px",
          marginInline: "auto",
          padding: "10px",
        }}
      >
        {/* <h1>{user.role === 'main_treasury' ? "MAIN TREASURY" : "SUB TREASURY"}</h1> */}
        <Container fluid className="px-0">
          <div className="d-flex justify-content-between align-items-center w-100">
            {/* <div className="d-flex align-items-center">
              <Form className="navbar-search">
                <Form.Group id="topbarSearch">
                  <InputGroup className="input-group-merge search-bar">
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control
                      value={searchTerm}
                      onChange={({ target: { value } }) => setSearchTerm(value)}
                      type="text"
                      placeholder="Search"
                    />
                  </InputGroup>
                </Form.Group>
              </Form>
            </div> */}

            <center>
              <h4
                style={{ color: "white", marginLeft: 5, fontWeight: "bold" }}
                className="display-sm-5"
              >
                TREASURY BUDGET AND EXPENDITURE ASSURANCE MANAGEMENT SYSTEM
                <br />
                <span style={{ marginLeft: "18px", fontSize: "xs" }}>
                  (T-BEAMS)
                </span>
              </h4>
              {user && user.mda_name && user.mda_name !== "" ? (
                <h4
                  style={{ color: "white", marginLeft: 5, fontWeight: "bold" }}
                >
                  {user && user.mda_name}
                </h4>
              ) : null}
            </center>

            <Nav className="align-items-center">
              <Dropdown
                as={Nav.Item}
                show={showDropdown}
                onToggle={handleToggle}
                autoClose="auto"
                // onToggle={markNotificationsAsRead}
              >
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="dropdown-autoclose-outside"
                  className="text-dark icon-notifications me-lg-3"
                >
                  <span className="icon icon-sm">
                    <FontAwesomeIcon
                      icon={faBell}
                      className="bell-shake text-white"
                    />
                    {areNotificationsRead ? null : (
                      <span className="icon-badge rounded-circle unread-notifications">
                        1{notifications.legth}{" "}
                      </span>
                    )}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                  <ListGroup className="list-group-flush">
                    <Nav.Link
                      href="#"
                      className="text-left fw-bold border-bottom border-light py-1"
                    >
                      Notifications
                    </Nav.Link>

                    {notifications.map((n) => (
                      <Notification key={`notification-${n.id}`} {...n} />
                    ))}

                    <Dropdown.Item
                      className="text-center text-primary fw-bold py-3
                    "
                      // href={'.notifications'}
                      onClick={handleViewAllClick}
                    >
                      View all
                    </Dropdown.Item>
                  </ListGroup>
                </Dropdown.Menu>
              </Dropdown>
              {/* {JSON.stringify(user)} */}
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    <Image
                      src={Profile3}
                      className="user-avatar md-avatar rounded-circle"
                    />
                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <span className="mb-0 font-small fw-bold text-white">
                        {/* {user.fullname} */}
                      </span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  {/* <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My
                  Profile
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" />{' '}
                  Messages
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserShield} className="me-2" />{' '}
                  Support
                </Dropdown.Item> */}

                  {/* <Dropdown.Divider /> */}
                  <Dropdown.Item
                    className="fw-bold"
                    onClick={() => history(Routes.pv.UserProfile)}
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-danger me-2"
                    />{" "}
                    Profile
                  </Dropdown.Item>

                  <Dropdown.Item className="fw-bold" onClick={handleLogout}>
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      className="text-danger me-2"
                    />{" "}
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </>
  );
};
